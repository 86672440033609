import React from "react";

interface HeaderProps {
    data_bs_theme: string;
}
  
const Footer: React.FC<HeaderProps> = ({ data_bs_theme }) => {
    return (
        <footer className=" text-center py-3" data-bs-theme={data_bs_theme}>
            <div className="footer-bottom">
                <p>© 2024 All Rights Reserved</p>
                <div className="footer-links">
                    <a href="#privacy-policy">Privacy Policy</a>
                    <span className="tooltip-container">
                        <a 
                            href="#terms-conditions" 
                            title="Use of this platform is subject to our Terms and Conditions available on request from your account manager"
                        >
                            Terms and Conditions
                        </a>
                    </span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
