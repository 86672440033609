import React from 'react';
import { LiveIcon } from '../Global/Icons';
import { Accordion } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FadeAccordion, LinkButton } from '../Global/Transitions';
import TestProps from '../../Utilities/TestProps';



interface ProjectCommercialsProps {
  status: string;
}

const ProjectCommercials: React.FC<ProjectCommercialsProps> = ({ status }) => {
  const { id } = useParams();
  const props = TestProps[status];
  const isActive = ['Quote Available'].includes(status);
  const isViewable = !['Quote Requested', 'Created'].includes(status);
  const isDisabled = !['Quote Available', 'Quote Requested'].includes(status);
  
  return (
    <FadeAccordion defaultActiveKey='0' >
      <Accordion.Item eventKey={isDisabled ? '1' : '0'}>
        <Accordion.Button as='h3' className={isActive ? 'text-success-emphasis' : ''}>
          {isActive && 'Quote Available' && <LiveIcon className='ms-n2 me-1 text-success' />}
          Commercials
        </Accordion.Button>
        <Accordion.Body className='pb-5'>
          <p>{props?.quoteText}</p>
          {isViewable ? <LinkButton to={'/projects/' + id + '/quote'}>View Quote</LinkButton> : null}
        </Accordion.Body>
      </Accordion.Item>
    </FadeAccordion>
  );
};

export default ProjectCommercials;