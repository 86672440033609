import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {  PinIcon } from './Icons';
import { Chat, FloatingField } from './Transitions';
import { Col, Nav, Row, Tab, Form, Button, Pagination } from 'react-bootstrap';
import Message from '../../Models/Message';
import { useMsal } from '@azure/msal-react';
import { PostMessages, UploadFilesInChatFolder } from '../../Services/PtaasApiService';


interface MessagingProps {
  messages: Message[];
}

const Messaging: React.FC<MessagingProps> = ({ messages }) => {
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState('');
  const { instance, accounts } = useMsal();
  const [show,setshow] = useState(false);
  const [currentChat, setCurrentChat] = useState<Message | null>(null);
  const [localMessages, setLocalMessages] = useState<Message[]>(messages); 
  const [currentPage, setCurrentPage] = useState(1);
  const messagesPerPage = 7;
  const navigate = useNavigate(); // Initialize the navigation hook

  const totalPages = Math.ceil(localMessages.length / messagesPerPage);


  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const messageId = queryParams.get('id');
  
    if (messageId ) {
      // Handle URL-based selection
      const messageIdAsNumber = parseInt(messageId, 10);
  
      if (!isNaN(messageIdAsNumber)) {
        const selectedMessage = localMessages.find((msg) => msg.id === messageIdAsNumber);
  
        if (selectedMessage) {
          setCurrentChat(selectedMessage); // Set chat based on URL
          const pageNumber = Math.ceil((localMessages.indexOf(selectedMessage) + 1) / messagesPerPage);
          setCurrentPage(pageNumber);
        }
      }
    } else if (!currentChat && localMessages.length > 0) {
      // Fallback to the first chat if no current chat is set
      setCurrentChat(localMessages[0]);
      setCurrentPage(1);
    }
   
  }, [location.search, localMessages, currentChat]);
  
  const handleChatClick = (message: Message) => {
    setCurrentChat(message); // Update the current chat
    setCurrentPage(Math.ceil((localMessages.indexOf(message) + 1) / messagesPerPage)); // Update the page
    navigate(`?id=${message.id}`);
  };
  


  function timeAgo(isoDate: string) {
    const date = new Date(isoDate);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (diffInSeconds < 60) {
      return 'just now';
    } else if (diffInSeconds < 3600) { // less than 1 hour
      const mins = Math.floor(diffInSeconds / 60);
      return `${mins} minute${mins > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 86400) { // less than 24 hours
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
  }

  
  const handleSend = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    
  
    if (!form.checkValidity()) {
      setValidated(true);
      setshow(false); // Enable the submit button again if validation fails
      return; // Exit early if validation is incorrect
    } else {
      setValidated(false); // Reset validation state if form is valid
      setshow(true); // Disable the submit button while processing the submission
    }
      try {
       
        const author = 'Jira Staging API';
  
        // Get the latest message from localMessages (important for appending to correct state)
        const latestMessage = localMessages.find(msg => msg.id === currentChat?.id);
        const pageNumber = Math.ceil((localMessages.indexOf(latestMessage) + 1) / messagesPerPage);

        // If the message exists, append the new message to the contents
        const updatedMessage = {
          ...latestMessage,
          contents: latestMessage?.contents
            ? [...latestMessage.contents, { text: message, author }]
            : [{ text: message, author }]
        };
  
        // Send the new message to the API
        await PostMessages(instance, accounts[0], currentChat?.id, message, false, author);
  
        // Update the localMessages array with the updated message
        setLocalMessages(prevMessages =>
          prevMessages.map(msg => msg.id === currentChat?.id ? updatedMessage : msg)
        );
  
        // Clear the input field
        setMessage('');
        setCurrentPage(pageNumber);
  
        // Set the updated message as the current chat
        const cindex=localMessages.findIndex(msg=>msg.id===currentChat?.id);
       setCurrentChat(localMessages[cindex]);

  
        setValidated(false);
        
  
      } catch (error) {
        console.error('Failed to send message:', error);
      } finally {
        setshow(false);
      }
    
  };
  
  
  function getFirstLetter(name: string) {
    return name.charAt(0).toUpperCase();
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const inputRef = useRef(null);
 
  const handlePinClick = () => {
    inputRef.current?.click();
    console.log("PinIcon clicked");
  };

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
     
      try {
        await UploadFilesInChatFolder(instance, accounts[0], currentChat?.id, file);
        setMessage(file.name);
       
        console.log('File uploaded successfully:', file.name);
      } catch (error) {
        console.error('Failed to upload file:', error);
      }
    }
  };

  const currentMessages = localMessages.slice((currentPage - 1) * messagesPerPage, currentPage * messagesPerPage);
  
  return (
    <Tab.Container activeKey={currentChat ? currentChat.id : localMessages[0].id}>
      <Row className='m-0' style={{ minHeight: '83vh' }}>
        <Col sm={3} className='border-end p-0'>
          <Row className='border-bottom'>
            <Col sm={6} as='h2' className='m-0 ps-4 py-3'>Recent</Col>
          </Row>
          <Nav className='flex-column' variant='pills'>
            {currentMessages.map((message) => (
              <Nav.Link key={message.id} eventKey={message.id} className='p-3' onClick={() => handleChatClick(message)}>
                <h3>{message.title}</h3>
                <p className='small mb-2' style={{ opacity: 0.4 }}>
                  <span>{message.project}</span>
                  <span className='mx-1'>•</span>
                  <span> {timeAgo(message.created)}</span>
                </p>
              </Nav.Link>
            ))}
          </Nav>
          <Pagination className="pagination">
            {[...Array(totalPages)].map((_, index) => (
              <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </Col>
        <Col sm={9} className='bg-lighter p-0'>
          <div style={{ height: '83vh', display: 'flex', flexDirection: 'column' }}>
            <div style={{ flex: 1, overflowY: 'auto' }}>
              <Tab.Content className='px-4 py-3 position-relative'>
                {localMessages.map((message) => (
                  <Tab.Pane key={message.id} eventKey={message.id}>
                    <h2>{message.title}</h2>
                    <div className='pb-6 pt-3'>
                      <div className='ps-5 mt-n5 ms-3'>
                        {message.contents.map((chat, index) => (
                          <div key={index} className={`message-container ${chat.author === 'Jira Staging API' ? 'admin' : 'user'}`} data-message-id={message.id}>
                            {chat.author === 'Jira Staging API' ? (
                              <>
                                <div className="initials-admin">
                                  {getFirstLetter('User')}
                                </div>
                                <div>
                                  <Chat key={index} sent={false}>
                                    {chat.text}
                                  </Chat>
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  <Chat key={index} sent={true}>
                                    {chat.text}
                                  </Chat>
                                </div>
                                <div className="initials-user">
                                  {getFirstLetter('Admin')}
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </div>
            <div className='p-4'>
              <Form noValidate validated={validated} onSubmit={handleSend}>
                <FloatingField type='textarea' className='w-100' label='Send a message' value={message} onChange={(e) => setMessage(e.target.value)} style={{ height: 120 }} />
                <Form.Group className='z-4 position-relative float-end me-4' style={{ marginTop: -60 }}>
                
                <input style={{ display: 'none' }} ref={inputRef} type="file" onChange={handleFileSelect} />
                <PinIcon onClick={handlePinClick} />
      
                  <Button className='ms-3' disabled={show}  type='submit'>Send Message</Button>
                </Form.Group>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default Messaging;